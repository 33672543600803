import React, { useState } from 'react';
import './modalNotification.scss';
import { useDispatch } from 'react-redux';
import { AntdDropdown, Text, Button, Input, Icon } from '@ui-kit';
import { Notification, updateAdminProjectPropertyTC } from '@entities';
import Dollar from '@ui-kit/icons/dollar-gray500.svg';
import Clock from '@ui-kit/icons/clock-gray500.svg';
//import Crown from '@ui-kit/icons/crown-gray500.svg';
import Process from '@ui-kit/icons/edit-3-gray500.svg';
import Download from '@ui-kit/icons/download-gray500.svg';

const PRODUCT_DROPDOWN_LIST = [
  new Notification({
    message: 'Sketch is ready',
    markType: 'sketchReady',
    type: 'projectNotification',
    ico: Dollar,
  }),
  new Notification({
    message: 'Illustration is ready',
    markType: 'illustrationReady',
    type: 'projectNotification',
    ico: Dollar,
  }),
  new Notification({
    message: 'Waiting for a response',
    markType: 'waitingResponse',
    type: 'projectNotification',
    ico: Clock,
  }),
  // new Notification({
  //   message: 'Switching to exclusive license',
  //   markType: 'switchingToExclusive',
  //   type: 'projectNotification',
  //   ico: Crown,
  // }),
  new Notification({
    message: 'Work in progress',
    markType: 'workProgress',
    type: 'projectNotification',
    ico: Process,
  }),
  new Notification({
    message: 'Source files are ready',
    markType: 'sourceReady',
    type: 'projectNotification',
    ico: Download,
  }),
];

const ModalNotification = ({
  showNotification = () => {},
  notificationsToUser = [],
  rate = {},
  unpaidHours
}) => {
  const option = notificationsToUser[0] || {};

  const [active, setActive] = useState(option?.value || '');
  const [hours, setHours] = useState(''); //option?.hours || ''
  const [minutes, setMinutes] = useState(''); //option?.minutes || ''
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  PRODUCT_DROPDOWN_LIST.forEach((notification) => {
    notification.value = notification.markType;
    notification.name = notification.message;
  });

  const onChooseNotification = (item) => {
    setActive(item.value);
  };

  const renderDropDowns = (item) => {
    const classes = ['input-search__item'];
    const { value, ico, message } = item;

    if (value === active) {
      classes.push('active');
    }

    return (
      <div key={value} className={classes.join(' ')}>
        {item.ico && <Icon src={ico} alt={value} />}
        <Text as="h5" onClick={() => onChooseNotification(item)}>
          {message}
        </Text>
      </div>
    );
  };

  const onBackgroundClick = (e) => {
    if (e.target.id === 'notification-background') {
      showNotification(false);
    }
  };

  const onPublish = () => {
    const rateValueToNumber = +rate.value;
    const minutesToNumber = +minutes;
    const hoursToNumber = +hours;
    let totalToFloat = +`${hours}.${minutesToNumber}`;

    if (isNaN(totalToFloat)) {
      totalToFloat = 0;
    }

    if (totalToFloat > +unpaidHours) {
      return setErrors({ hours: `Maximum fields value is ${unpaidHours}`, minutes: ' ' });
    }

    const step = 15;

    if (!!minutesToNumber) {
      if (minutesToNumber < step) {
        return setErrors({ minutes: `The minimum value is ${step}` });
      }

      if (!!(minutes % step)) {
        return setErrors({
          minutes: `Step between values must be ${step} (15, 30, 45)`,
        });
      }
    }

    setErrors({});

    const notificationEntity = PRODUCT_DROPDOWN_LIST.find(
      (notification) => notification.value === active
    );

    if (!notificationEntity) {
      return;
    }

    const minutesValue = ((minutesToNumber * rateValueToNumber) / 60).toFixed(
      2
    );
    const notificationValue =
      hoursToNumber * rateValueToNumber + parseFloat(minutesValue);

    notificationEntity['hours'] = hoursToNumber;
    notificationEntity['minutes'] = minutesToNumber;
    notificationEntity['notificationValue'] = notificationValue;
    notificationEntity['rate'] = rate.type;

    if (notificationEntity.id) {
      dispatch(
        updateAdminProjectPropertyTC({
          name: 'notificationsToUser',
          value: JSON.stringify([notificationEntity]),
          callback: () => {
            showNotification(false);
          },
        })
      );
    }
  };

  return (
    <div
      id="notification-background"
      className="background-default"
      onMouseDown={onBackgroundClick}
    >
      <div className="notification-popup__wrapper">
        <Text as="h4" className="notification-popup__title">
          Notice to the client
        </Text>
        <Text as="h6" className="notification-popup__subtitle">
          Please complete all fields before posting.
        </Text>
        <div className="notification-popup__list">
          <Text as="h6" className="notification-popup__list-title">
            Notification
          </Text>
          <AntdDropdown
            data={PRODUCT_DROPDOWN_LIST}
            placeholder="Choose notification"
            value={active}
            id="notification-dropdown"
            renderOption={renderDropDowns}
          />
        </div>
        <div className="notification-hours-minutes">
          <Input
            placeholder="Hours"
            name="hours"
            type="number"
            label="Hours"
            value={hours}
            maxLengthCustom={99}
            className={errors.hours && 'form-item__error'}
            onChange={(e) => setHours(e.target.value)}
          />
          <Input
            placeholder="Minutes"
            name="minutes"
            type="number"
            label="Minutes"
            value={minutes}
            maxLengthCustom={59}
            className={errors.minutes && 'form-item__error'}
            onChange={(e) => setMinutes(e.target.value)}
          />
        </div>
        {errors.hours && (
          <Text as="span" className="error">
            {errors.hours}
          </Text>
        )}
        {errors.minutes && (
          <Text as="span" className="error">
            {errors.minutes}
          </Text>
        )}

        <Button className="notification__btn publish" onClick={onPublish}>
          Publish
        </Button>
        <Button
          className="notification__btn"
          theme="secondary-gray"
          onClick={() => showNotification(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ModalNotification;
