import React, { useState } from 'react';
import './countries-popup.scss';

import { Text, Button, Icon } from '@ui-kit';
import { useDispatch } from 'react-redux';
import { CountriesDropDown } from '@widgets';
import { updateUserSettingsCountryTC } from '@entities';

const CountriesPopup = ({ customer = {} }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [countryState, setCountryState] = useState(customer.country);

  const onChooseCountry = (country) => {
    setErrors({});

    const code = country?.code;
    setCountryState(code);
  };

  const goBack = (e) => {
    e.stopPropagation()
    window.history.back();
  }

  const onSubmit = () => {
    if (!countryState) {
      return setErrors({country: "* Country is required"})
    }

    setErrors({});
    const user = { ...customer, country: countryState };
    dispatch(updateUserSettingsCountryTC(user));
  };

  return (
    <div 
      id="countries-background" 
      className="background-default countries-popup__bkg"
    >
      <div className="countries-popup__wrapper">
        <Icon src="/images/country_map.jpeg" className="countries-popup__img" />
        <Text as="h4" className="countries-popup__title">
          Select your country
        </Text>
        <Text as="h6" className="countries-popup__subtitle">
          Please select your country so that the system processes your order
          correctly.
        </Text>
        <div>
          <title className="countries-dropdown__title">Country</title>
          <CountriesDropDown
            errors = {errors}
            current={countryState}
            onChooseCountry={onChooseCountry}
            isInPopup={true}
          />
        </div>

        <div className="countries-popup__actions">
          <Button theme="secondary-gray" onClick={goBack}>Cancel</Button>
          <Button onClick={onSubmit}>Apply</Button>
        </div>
      </div>
    </div>
  );
};

export default CountriesPopup;
