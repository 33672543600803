import React, { useState, useRef } from 'react';
import './profile-purchases.scss';

import { WaitingPreloader } from '@widgets';
import { Icon, Text, Button, Tooltip } from '@ui-kit';
import { ADMIN_PROJECTS_URL, ADMIN_USER_PROJECTS_URL } from '@app/routes';
import DownloadIcon from '@ui-kit/icons/download-white.svg';

const CompletedItem = ({ item = {}, makeRequest, isUser }) => {
  const preloaderKeyRef = useRef();
  const [isDownload, setIsDownload] = useState(false);

  const {
    renderImageUrl,
    dir,
    title,
    followingData,
    rate,
    id,
    archive,
    paidAt,
  } = item;
  const license = rate?.type === 'hourlyWithoutLicense' ? 'Exclusive' : 'Extended commercial';
  const paidDate = paidAt ? paidAt : +followingData[7]?.date;
  const date = new Date(paidDate).toLocaleString(undefined, {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit'
  });
  const isDisabled = (archive === 'http://' || archive === 'https://' || archive === 'null');

  const download = async () => {
    setIsDownload(true);

    preloaderKeyRef.current = 'downloadArchive';
    const product =  await makeRequest({ dir, archive });

    setIsDownload(false);
    return product;
  };

  const projectUrl = () => {
    if (!isUser) {
      return `${ADMIN_PROJECTS_URL.pathname}?project_asset_id=${id}&admin_info_active=info`;
    }
    return `${ADMIN_USER_PROJECTS_URL.pathname}?project_asset_id=${id}&user_info_active=info`;
  }

  const infoTooltip = (
    <div className="product__tooltip">
      <div className="product__tooltip-line">
        <Text as="h5">License</Text>
        <Text as="h5" className="tooltip-name">
          {license || ''}
        </Text>
      </div>
      <div className="product__tooltip-line">
        <Text as="h5">Date</Text>
        <Text as="h5" className="tooltip-name">
          {date || ''}
        </Text>
      </div>
    </div>
  );

  return (
    <div
      className="admin__table-row"
    >
      <div className="product-name d-flex ai-center">
        <div className="product-name__icon__wrapper pr">
          <a
            className="product__info-link"
            href={projectUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={renderImageUrl} />
          </a>
        </div>

        <div className="product-title">
          <Text as="h5">{title || ''}</Text>
          <Tooltip
              content={infoTooltip}
              position="top"
              ancorPosition="anchor-right-middle"
            >
              <div className="product-info">More info</div>
            </Tooltip>
        </div>
      </div>

        <div className="d-flex ai-center product-license">
          <Text as="h6">{license || ''}</Text>
        </div>

        <div className="product-date">
            <Text as="h6">{date || ''}</Text>
        </div>
        {isDownload && <WaitingPreloader keyText={preloaderKeyRef.current} />}
        <div className="product-download">
            <Button 
              size="sm" 
              iconLeft={DownloadIcon}
              onClick={download}
              isDisabled={isDisabled}
            >
              <Text as="span">Download</Text>
            </Button>
        </div>
    </div>
  );
};

export default CompletedItem;
