import React, {useState} from 'react';
import { Select } from 'antd';
import './antd-dropdown.scss';

import { Text, Icon } from '@ui-kit';
import CheckIcon from '@ui-kit/icons/check-def-accent600-md.svg'
import ArrowFlex from '@ui-kit/icons/chevron-gray500.svg';
import SearchIcon from "@ui-kit/icons/search-gray500.svg";

const AntdDropdown = (props) => {
  const {
    id = new Date(),
    mode = '',
    data = [],
    size= "large",
    value = '',
    search = false,
    placeholder = '',
    className = '',
    iconLeft = false,
    iconRight = false,
    isDisabled = false,
    renderOption = () => {},
    onChooseCountry = () => {},
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const containerClasses = ['antd-dropdown__container', ...className];

  if (isDisabled) {
    containerClasses.push('disabled');
  }

  if (iconLeft) {
    containerClasses.push('icon__left');
  }

  if (iconRight || search) {
    containerClasses.push('icon__right');
  }

  const onChange = (value) => {
    onChooseCountry({code: value});
  };

  /*const onSearch = (value) => {
    console.log('search:', value);
  };*/

  const onOpen = () => {
    setIsOpen(!isOpen);
  }

  const renderItem = (item, index) => {
    if(renderOption) {
        return (
            <Select.Option 
                value={item.value} 
                label={item.name} 
                key={`option-${index}`}
            >
                {renderOption(item, index)}
            </Select.Option>
        );
    };

    return (
        <Select.Option 
            value={item.value} 
            label={item.name}
            key={`option-${index}`}
        >
            <Text as="h5">{item.name}</Text>
        </Select.Option>
    );
  };

  const renderIconSearch = () => {
    if (isOpen && search) {
        return <Icon src={SearchIcon} className="search" />
    };

    if (isOpen) {
        return <Icon src={ArrowFlex} className="rotate" />
    };

    return <Icon src={ArrowFlex} />
  };

  return (
    <div className={containerClasses.join(' ')} id={id}>
        <Select
            size={size}
            mode={mode}
            showSearch={search}
            value={value}
            placeholder={placeholder}
            menuItemSelectedIcon={<Icon src={CheckIcon} />}
            suffixIcon={renderIconSearch()}
            optionFilterProp="label"
            onChange={onChange}
            onDropdownVisibleChange={onOpen}
            //onSearch={onSearch}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            disabled={isDisabled}
        >
            {data.map((item, index) => renderItem(item, index))}
        </Select>
    </div>
  );
};

export default AntdDropdown;

/*
 <Dropdown
        data={PRODUCT_DROPDOWN_LIST}
        mode="multiple"
        search={true}
        placeholder= "Sort by"
        value={sortBy}
        id="products-dropdown"
        renderOption={renderDropDowns}
      />
*/
