import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './productsPanelWrapper.scss';

import { ProductsMenu } from '@widgets';
import {
    isOnline,
    getMsConnected, 
    setMsConnectedAC, 
    Snackbar, 
    setAlertAC,
  } from '@entities';


const ProductsPanelWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const isMsConnected = useSelector(getMsConnected);
    const isUserOnline = useSelector(isOnline);

    useEffect(() => {
        if (!isUserOnline) {
          return;
        };
    
        initSocket();
        // eslint-disable-next-line
    }, [isUserOnline]);

    function initSocket() {
        const socket = window.socket;
        socket.socket.send(JSON.stringify({ event: 'ms.products.connection' }));
    
        socket.socket.onmessage = (event) => {
          const data = event?.data;
          if (!data) {
            return;
          }
    
          const toJson = JSON.parse(data);
          const customEvent = toJson.event;
    
          switch (customEvent) {
            case 'ms.products.connected': {
              if (!toJson?.data?.value) {
                return;
              }
    
              setSnackbar({
                msg: 'Connection to ms products was established',
                status: 200,
              });
    
              console.log('[WSS]', 'Connection to ms products was established')
    
              dispatch(setMsConnectedAC(true));
    
              break;
            }
    
            case 'ms.products.processing.continuation': {
              setSnackbar({
                msg: 'Successfully processed... Processing',
                status: 200,
              });
              console.log('[WSS]', 'Successfully processed... Processing')
              dispatch(setMsConnectedAC(false));
              break;
            }
    
            case 'ms.products.processing.finish': {
              setSnackbar({
                msg: 'Processing finished. Bucket is empty.',
                status: 200,
              });
              console.log('[WSS]', 'Processing finished. Bucket is empty.')
              dispatch(setMsConnectedAC(true));
              break;
            }
    
            case 'ms.products.processing.error': {
              setSnackbar({
                msg: 'Processing error.',
                status: 500,
              });
              console.log('[WSS]', 'Processing error.')
              dispatch(setMsConnectedAC(true));
              break;
            }
    
            default:
              return;
          }
        };
      };
    
      const setSnackbar = (message) => {
        const snackbar = new Snackbar({
          status: message.status,
          message: (
            <div className="">
              <h5 className="snack__header">
                { message.msg }
              </h5>
            </div>
          ),
        });
    
        dispatch(setAlertAC(snackbar));
      };
    
      const onSubmitProducts = () => {
        dispatch(setMsConnectedAC(false));
    
        window.socket.send('ms.products.processing.start', {});
      };

  return (
    <div className="products__panel-wrapper">
        <ProductsMenu 
            onSubmitProducts={onSubmitProducts} 
            isMsConnected={isMsConnected}
        />
        {children}
    </div>
  );
};

export default ProductsPanelWrapper;
