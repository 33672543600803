const SLIDER_TOP = [
   {
      "id":"tropical-island-beach-with-old-pirate-ship",
      "pathToBrowse":"2208.w026.n002.2325B.p1.2325/browse.2208.w026.n002.2325B.p1.2325.jpg",
      "alt":"slide1",
      "price":2,
      "userId":2,
      "linkId":"tropical-island-beach-with-old-pirate-ship",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"woman-working-at-loft-office-or-coworking",
      "pathToBrowse":"2110.w023.n001.1204B.p1.1204/browse.2110.w023.n001.1204B.p1.1204.jpg",
      "alt":"slide2",
      "price":2,
      "userId":2,
      "linkId":"woman-working-at-loft-office-or-coworking",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"cartoon-city-street-with-nice-houses-and",
      "pathToBrowse":"2212.w020.n002.1894B.p15.1894/browse.2212.w020.n002.1894B.p15.1894.jpg",
      "alt":"slide3",
      "price":2,
      "userId":2,
      "linkId":"cartoon-city-street-with-nice-houses-and",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"little-black-girl-cartoon-character-for-animation",
      "pathToBrowse":"2207.w020.n002.1548.MotionCharacter.p15.1548/browse.2207.w020.n002.1548.MotionCharacter.p15.1548.jpg",
      "alt":"slide4",
      "price":2,
      "userId":2,
      "linkId":"little-black-girl-cartoon-character-for-animation",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"little-girl-cartoon-character-for-animation-front",
      "pathToBrowse":"2209.w020.n002.1683B.p15.1683/browse.2209.w020.n002.1683B.p15.1683.jpg",
      "alt":"slide5",
      "price":2,
      "userId":2,
      "linkId":"little-girl-cartoon-character-for-animation-front",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"cartoon-beautiful-girl-with-blonde-hair-wear",
      "pathToBrowse":"2211.w020.n002.1822A.p30.1822/browse.2211.w020.n002.1822A.p30.1822.jpg",
      "alt":"slide6",
      "price":2,
      "userId":2,
      "linkId":"cartoon-beautiful-girl-with-blonde-hair-wear",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"happy-boy-waiting-school-bus-in-city",
      "pathToBrowse":"2207.w023.n003.2770B.p1.2770/browse.2207.w023.n003.2770B.p1.2770.jpg",
      "alt":"slide7",
      "price":2,
      "userId":2,
      "linkId":"happy-boy-waiting-school-bus-in-city",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"fishing-boats-at-pier-in-lake-river",
      "pathToBrowse":"2212.w015.n003.1103B.p15.1103/browse.2212.w015.n003.1103B.p15.1103.jpg",
      "alt":"slide8",
      "price":2,
      "userId":2,
      "linkId":"fishing-boats-at-pier-in-lake-river",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"pink-magic-castle-on-floating-island-in",
      "pathToBrowse":"2207.w020.n002.1566B.p15.1566/browse.2207.w020.n002.1566B.p15.1566.jpg",
      "alt":"slide9",
      "price":2,
      "userId":2,
      "linkId":"pink-magic-castle-on-floating-island-in",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"open-space-office-workplace-interior-with-tables",
      "pathToBrowse":"2210.w015.n003.1011B.p15.1011/browse.2210.w015.n003.1011B.p15.1011.jpg",
      "alt":"slide10",
      "price":2,
      "userId":2,
      "linkId":"open-space-office-workplace-interior-with-tables",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"space-game-background-night-alien-fantasy-landscape",
      "pathToBrowse":"2004.w020.n001.244B.p15.244/browse.2004.w020.n001.244B.p15.244.jpg",
      "alt":"slide11",
      "price":2,
      "userId":2,
      "linkId":"space-game-background-night-alien-fantasy-landscape",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"motel-at-night-highway-roadside-old-building",
      "pathToBrowse":"2207.w054.n005.76B.p1.76/browse.2207.w054.n005.76B.p1.76.jpg",
      "alt":"slide12",
      "price":2,
      "userId":2,
      "linkId":"motel-at-night-highway-roadside-old-building",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"cute-cartoon-astronaut-flying-on-rocket-in",
      "pathToBrowse":"2301.w026.n002.3073B.p1.3073/browse.2301.w026.n002.3073B.p1.3073.jpg",
      "alt":"slide13",
      "price":2,
      "userId":2,
      "linkId":"cute-cartoon-astronaut-flying-on-rocket-in",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"game-icons-with-heart-lightning-key-crown",
      "pathToBrowse":"2108.w032.n003.51A.p1.51/browse.2108.w032.n003.51A.p1.51.jpg",
      "alt":"slide14",
      "price":2,
      "userId":2,
      "linkId":"game-icons-with-heart-lightning-key-crown",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"gold-award-badges-for-win-in-game",
      "pathToBrowse":"2109.w032.n003.61A.p1.61/browse.2109.w032.n003.61A.p1.61.jpg",
      "alt":"slide15",
      "price":2,
      "userId":2,
      "linkId":"gold-award-badges-for-win-in-game",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"set-cartoon-of-casino-poker-game-slot",
      "pathToBrowse":"2302.w020.n002.1999A.p30.1999/browse.2302.w020.n002.1999A.p30.1999.jpg",
      "alt":"slide16",
      "price":2,
      "userId":2,
      "linkId":"set-cartoon-of-casino-poker-game-slot",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"cartoon-set-of-cute-princess-character-with",
      "pathToBrowse":"2301.w020.n002.1941B.p15.1941/browse.2301.w020.n002.1941B.p15.1941.jpg",
      "alt":"slide17",
      "price":2,
      "userId":2,
      "linkId":"cartoon-set-of-cute-princess-character-with",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"police-inspector-or-private-detective-on-background",
      "pathToBrowse":"2207.w023.n003.2653B.p1.2653/browse.2207.w023.n003.2653B.p1.2653.jpg",
      "alt":"slide17",
      "price":2,
      "userId":2,
      "linkId":"police-inspector-or-private-detective-on-background",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   }
];

const SLIDER_BOTTOM = [
   {
      "id":"modern-living-room-with-purple-furniture-and",
      "pathToBrowse":"2206.w015.n003.877B.p15.877/browse.2206.w015.n003.877B.p15.877.jpg",
      "alt":"slide1",
      "price":2,
      "userId":2,
      "linkId":"modern-living-room-with-purple-furniture-and",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"fast-food-retro-cafe-interior-with-tables",
      "pathToBrowse":"2211.w020.n002.1809B.p15.1809/browse.2211.w020.n002.1809B.p15.1809.jpg",
      "alt":"slide2",
      "price":2,
      "userId":2,
      "linkId":"fast-food-retro-cafe-interior-with-tables",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"tropical-island-with-treasure-chest-and-broken",
      "pathToBrowse":"2105.w026.n002.415B.p1.415/browse.2105.w026.n002.415B.p1.415.jpg",
      "alt":"slide3",
      "price":2,
      "userId":2,
      "linkId":"tropical-island-with-treasure-chest-and-broken",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"wild-african-animals-zoo-characters-vector-cartoon",
      "pathToBrowse":"2205.w054.n004.30A.p1.30/browse.2205.w054.n004.30A.p1.30.jpg",
      "alt":"slide4",
      "price":2,
      "userId":2,
      "linkId":"wild-african-animals-zoo-characters-vector-cartoon",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"smartphone-lock-screens-with-underwater-animals-cartoon",
      "pathToBrowse":"2108.w026.n002.723B.p0.723/browse.2108.w026.n002.723B.p0.723.jpg",
      "alt":"slide5",
      "price":2,
      "userId":2,
      "linkId":"smartphone-lock-screens-with-underwater-animals-cartoon",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"white-bear-and-penguin-fishing-cute-wild",
      "pathToBrowse":"2111.w015.n001.584B.p30.584/browse.2111.w015.n001.584B.p30.584.jpg",
      "alt":"slide6",
      "price":2,
      "userId":2,
      "linkId":"white-bear-and-penguin-fishing-cute-wild",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"cryonics-laboratory-empty-interior-with-equipment-and",
      "pathToBrowse":"2010.w020.n001.789B.p15.789/browse.2010.w020.n001.789B.p15.789.jpg",
      "alt":"slide7",
      "price":2,
      "userId":2,
      "linkId":"cryonics-laboratory-empty-interior-with-equipment-and",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"young-couple-looking-on-night-city-from",
      "pathToBrowse":"2301.w030.n003.568B.p1.568/browse.2301.w030.n003.568B.p1.568.jpg",
      "alt":"slide8",
      "price":2,
      "userId":2,
      "linkId":"young-couple-looking-on-night-city-from",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"open-and-closed-magic-books-of-spells",
      "pathToBrowse":"2211.w054.n005.219B.p1.219/browse.2211.w054.n005.219B.p1.219.jpg",
      "alt":"slide9",
      "price":2,
      "userId":2,
      "linkId":"open-and-closed-magic-books-of-spells",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"young-couple-looking-on-night-city-from",
      "pathToBrowse":"2211.w020.n002.1816B.p15.1816/browse.2211.w020.n002.1816B.p15.1816.jpg",
      "alt":"slide10",
      "price":2,
      "userId":2,
      "linkId":"young-couple-looking-on-night-city-from",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"girl-with-flying-books-with-magic-glow",
      "pathToBrowse":"2108.w023.n001.896B.p1.896/browse.2108.w023.n001.896B.p1.896.jpg",
      "alt":"slide11",
      "price":2,
      "userId":2,
      "linkId":"girl-with-flying-books-with-magic-glow",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"senior-writer-work-in-cabinet-on-typewriter",
      "pathToBrowse":"2209.w020.n002.1671B.p15.1671/browse.2209.w020.n002.1671B.p15.1671.jpg",
      "alt":"slide12",
      "price":2,
      "userId":2,
      "linkId":"senior-writer-work-in-cabinet-on-typewriter",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   },
   {
      "id":"mom-or-housewife-speaking-by-smartphone-with",
      "pathToBrowse":"2108.w023.n001.859B.p1.859/browse.2108.w023.n001.859B.p1.859.jpg",
      "alt":"slide13",
      "price":2,
      "userId":2,
      "linkId":"mom-or-housewife-speaking-by-smartphone-with",
      "user":{
         "firstName":"BriefStock",
         "lastName":"",
         "role":"briefstock",
         "ava":"2_avatar.jpg"
      },
      "illustration_formats":[
         "EPS",
         "JPEG"
      ]
   }
];

export const GALLERY_SLIDERS_QA = {
  SLIDER_TOP,
  SLIDER_BOTTOM,
};
