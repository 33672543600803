import React, { useState, useEffect } from 'react';
import '../profile.scss';
import { Text, AntdDropdown } from '@ui-kit';

const ProfileRole = ({ 
  formData = {},
  onChangeInput = () => {},
  user = {},
  disabled = false
}) => {
  const Roles = [
    { name: 'User', value: 'USER'}, 
    { name: 'Executor', value: 'EXECUTOR'}, 
    { name: 'Admin', value: 'ADMIN' }
  ];
  const SubRoles = [
    { name: ' ', value: '' },
    { name: 'Interviewer', value: 'INTERVIEWER' },
    { name: 'Illustrator', value: 'ILLUSTRATOR' },
    { name: 'Art Director', value: 'ART_DIRECTOR' },
    { name: 'Sketcher', value: 'SKETCHER' },
  ];
  const {id, role = '', subRole = ''} = user;
  const [roleValue, setRoleValue] = useState(role);
  const [subRoleValue, setSubRoleValue] = useState(subRole);
  const subRoleDisabled = formData?.role !== 'EXECUTOR' ? true : disabled;

  useEffect(() => {
    formData.role = role;
    formData.subRole = subRole;

    const currentRole = Roles.find(
      (item) => item.value === role
    );
    const currentSubRole = SubRoles.find(
      (item) => item.value === subRole
    );

    if (!currentRole && !currentSubRole) {
      setRoleValue('');
      setSubRoleValue('');
      return;
    }
    if (!currentRole && currentSubRole) {
      setSubRoleValue(currentSubRole?.value);
      setRoleValue('');
      return;
    }
    if (!currentSubRole && currentRole) {
      setRoleValue(currentRole?.value);
      setSubRoleValue('');
      return;
    }
    
    setRoleValue(currentRole?.value);
    setSubRoleValue(currentSubRole?.value);
    // eslint-disable-next-line
  }, [id]);

  const renderRolePreview = (event, index) => {
    const name = event.name;
    const value = event.value;
    let classes = ['pr', 'executor__wrapper', 'input-search__item'];

    if (!name) return;

    if (value === roleValue) {
      classes.push('active');
    }

    return (
      <div
        className={classes.join(' ')}
        key={`role-${index}`}
        onClick={() => onSelectValue(value, 'role')}
      >
        <Text as='span'>{name}</Text>
      </div>
    );
  };

  const renderSubRolePreview = (event, index) => {
    const name = event.name;
    const value = event.value;
    let classes = ['pr', 'executor__wrapper', 'input-search__item'];

    if (!name || name === ' ') return;

    if (value === subRoleValue) {
      classes.push('active');
    }

    return (
      <div
        className={classes.join(' ')}
        key={`subrole-${index}`}
        onClick={() => onSelectValue(value, 'subrole')}
      >
        <Text as='span'>{name}</Text>
      </div>
    );
  };

  const onSelectValue = (value, type) => {
    if (type === 'subrole') {
      if (roleValue !== 'EXECUTOR') {
        formData.subRole = '';
        setSubRoleValue('');
        return;
      }

      formData.subRole = value;
      setSubRoleValue(value)
      return;
    };

    if (value !== 'EXECUTOR') {
      formData.subRole = '';
      setSubRoleValue('');
    }

    formData.role = value;
    setRoleValue(value);
  };

  return (
    <>
      <div className="profile__input mb-20">
        <label className="dropdown-label">Role</label>
        <AntdDropdown
          id="input-role"
          data={Roles}
          placeholder="Select role"
          search={false}
          renderOption={renderRolePreview}
          value={formData?.role || ''}
          isDisabled={disabled}
          onChange={onChangeInput}
        />
      </div>
      <div className="profile__input mb-20">
        <label className="dropdown-label">Sub Role</label>
        <AntdDropdown
          id="input-subrole"
          data={SubRoles}
          placeholder="Select sub role"
          search={false}
          renderOption={renderSubRolePreview}
          value={formData?.subRole || ''}
          isDisabled={subRoleDisabled}
          onChange={onChangeInput}
        />
      </div>
    </>
  );
};

export default ProfileRole;
