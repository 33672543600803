import { createSelector } from 'reselect';

export const getUser = (state) => state.userData;

export const getUserPreloader = createSelector(
  getUser,
  (userData) => userData.isUserPreloader
);

export const isAuth = createSelector(getUser, (userData) => userData.isAuth);

export const isOnline = createSelector(
  getUser,
  (userData) => userData.isOnline
);

export const getCustomer = createSelector(
  getUser,
  (userData) => userData?.user || null
);

export const getUserId = createSelector(
  getUser,
  (userData) => userData?.user?.id || null
);

export const getUserRole = createSelector(
  getUser,
  (userData) => userData?.user?.role || null
);

export const getCartId = createSelector(
  getUser,
  (userData) => userData?.user?.basketId || null
);

export const getCart = createSelector(
  getUser,
  (userData) => userData?.cart || null
);

export const getInvoice = createSelector(
  getUser,
  (userData) => userData?.invoice || null
);

export const getNotifications = createSelector(
  getUser,
  (userData) => userData?.notifications || null
);

export const getServerError = createSelector(
  getUser,
  (userData) => userData.serverErrors
);

export const getActiveSearch = createSelector(
  getUser,
  (userData) => userData.search
);

export const getCurrenteSearch = createSelector(
  getUser,
  (userData) => userData.searchResult
);

export const getShowroomsProducts = createSelector(
  getUser,
  (userData) => userData.showroomsProducts
);

export const getCurrentUser = createSelector(
  getUser,
  (userData) => userData.currentUser
);

export const getCurrentUserAvatar = createSelector(
  getUser,
  (userData) => userData.currentUserAvatar
);

export const getStripeToken = createSelector(
  getUser,
  (userData) => userData.publicStripeToken
);

export const getLimitPopup = createSelector(
  getUser,
  (userData) => userData.isCartLimitPopupShow
);

export const getLimitCart = createSelector(
  getUser,
  (userData) => userData.isCartLimit
);

export const getCountry= createSelector(
  getCustomer,
  (customerData) => customerData.country
);

export const getLocale = createSelector(
  getUser,
  (userData) => userData.locale
);

export const getPaymentStatus = createSelector(
  getUser,
  (userData) => userData.paymentStatus
);

export const getActivatedEmailShow = createSelector(
  getUser,
  (userData) => userData.isActivatedEmailModalShow
)

export const getPurchasesLimit = createSelector(
  getUser,
  (userData) => userData.puschasesLimit
)

export const getModalLoginShow = createSelector(
  getUser,
  (userData) => userData.isModalLoginShow
)

export const getModalSignupShow = createSelector(
  getUser,
  (userData) => userData.isModalSignupShow
)

export const getModalRecoveryShow = createSelector(
  getUser,
  (userData) => userData.isModalRecoveryShow
)

export const getPaymentData = createSelector(
  getUser,
  (userData) => userData.paymentData
)

export const getUserBills = createSelector(
  getUser,
  (userData) => userData.bills
)

export const getBillsPaginationAmount = createSelector(
  getUser,
  (userData) => userData.billsPaginationAmount
)

export const getPaidProjects = createSelector(
  getUser,
  (userData) => userData.paidProjects
)

export const getPaidProducts = createSelector(
  getUser,
  (userData) => userData.paidProducts
);

export const getBillsLoading = createSelector(
  getUser,
  (userData) => userData.billsLoading
)

export const getPurchasesLoading = createSelector(
  getUser,
  (userData) => userData.purchasesLoading
)

export const getSearchPage = createSelector(
  getUser,
  (userData) => userData.searchPage
)

export const getDeviceType = createSelector(
  getUser,
  (userData) => userData.deviceType
)

export const getIsMobileScreenView = createSelector(
  getUser,
  (userData) => userData.isMobileScreen
)

export const getIsVisibleSubFooter = createSelector(
  getUser,
  (userData) => userData.isVisibleSubfooter
)
