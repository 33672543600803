import React, { useEffect, useContext, useState } from 'react';
import './adminProjectInfo.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Icon, Text, AntdDropdown, Button } from '@ui-kit';
import { StepperToggle, Licenses, UploadImage, DownloadInfoElement } from '@widgets';
import { AvatarLabel } from '@features';
import {
  setChatMessagesAC,
  setCurrentProjectAC,
  setProjectPropertieAC,
  setProjectExecutorsAC,
  setisProjectChangeAC,
  setCurrentProjectImageAC,
  Rate,
  getCustomer,
  getExecutors,
  projectInstance,
  getAdminProjectTC,
  uploadBinaryFileTC,
  saveAdminProjectTC,
  getProjectWasChanged,
  addProjectToArchiveTC,
  deleteProjectTC,
  getCurrentProjectImage,
  removeProjectFromArchiveTC,
  updateAdminProjectPropertyTC,
} from '@entities';
import { RADIO_ITEMS } from './licenses.constants';
import { ProjectsContext } from '@shared/hooks';
import PlusIcon from '@ui-kit/icons/plus-accent700.svg';

const { REACT_APP_PROJECTS_URL } = process.env;

const AdminProjectInfo = ({ pathTo, currentProject }) => {
  const dispatch = useDispatch();
  const isProjectChanged = useSelector(getProjectWasChanged);
  const currentUser = useSelector(getCustomer);
  const projectsContext = useContext(ProjectsContext);
  const [errors, setErrors] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isArchive,
    showCalendar,
    setActiveInfo,
    setStagePopUpShow,
    showNotification,
    getUploadArchivePopUp,
    setEditTimeSpentShow,
  } = projectsContext;

  useEffect(() => {
    if (!isArchive) {
      showCalendar(false, 0);
    }

    return () => {
      setErrors({})
    }
    // eslint-disable-next-line
  }, [currentProject, isArchive]);

  const {
    id,
    dir,
    rate,
    price,
    title,
    archive,
    executors,
    followingId,
    followingData,
    renderImageUrl,
    workingHoursSum,
    paidHours,
    currentStepsHours,
    user,
  } = currentProject;
  
  const isExecutors = !!executors?.length;
  //executorsObject is Object
  const executorsObject = useSelector(getExecutors);
  const isArchiveIsString = archive?.includes('http');
  const currentProjectImage = useSelector(getCurrentProjectImage);

  if (!id) {
    return;
  }

  const workingHoursSumToInt = (time) => {
    if(time) {
      const hour = +time.split('.')[0];
      let minute = +time.split('.')[1];
      minute = 1 / (60 / minute);
      return hour + minute;
    };

    return 0;
  };

  const unpaidHours = workingHoursSumToInt(workingHoursSum) - +paidHours;
  //executorsObject to Array
  const executorsList = Object.keys(executorsObject).map((key) => ({
    ...executorsObject[key],
    value: executorsObject[key].id,
    name: `${executorsObject[key].firstName}${
      executorsObject[key]?.lastName || ''
    }`,
  }));

  const defaultExecutorsList = Object.keys(executors).map((key) => ({
    ...executors[key],
    value: executors[key].id,
    name: executors[key].firstName,
  }));

  RADIO_ITEMS.forEach((item) => {
    item.isActive = item.value === rate.type ? true : false;
  });

  const changeLicense = (value) => {
    const rate = new Rate(null, value);
    dispatch(setProjectPropertieAC({ key: 'rate', value: rate }));
    dispatch(setisProjectChangeAC());
  };

  const renderPreview = () => {
    const url = currentProjectImage?.name
      ? URL.createObjectURL(currentProjectImage)
      : null;

    if (!url) {
      // if (!pathTo) {
      //   return <EmptyProject />;
      // }

      const src = pathTo ? `${REACT_APP_PROJECTS_URL}/${pathTo}` : renderImageUrl;

      return (
        <Icon
          src={src}
          className="user-project__preview-img"
        />
      );
    }

    return <Icon src={url} className="user-project__preview-img" />;
  };

  const onTitleChange = (e) => {
    const value = e.target.value;
    dispatch(setProjectPropertieAC({ key: 'title', value }));
    dispatch(setisProjectChangeAC());
  };

  const renderExecutorPreview = (event) => {
    const value = +event.value;
    const user = executorsObject[value];

    let classes = ['pr', 'executor__wrapper', 'input-search__item'];
    const activeExecutor = currentProject.executors.find(
      (executor) => executor.id === value
    );

    if (activeExecutor) {
      classes.push('active');
    }

    return (
      <div
        className={classes.join(' ')}
        key={`exector-${value}`}
        onClick={() => onSelectExecutor(user)}
      >
        <AvatarLabel author={user} alt={`exector-${value}`} size="xs"/>
      </div>
    );
  };

  const onSelectExecutor = (user) => {
    const isExecutorExist = executors.find(
      (executor) => executor.id === user.id
    );

    const flag = !isExecutorExist ? true : false;

    dispatch(setProjectExecutorsAC({ data: user, flag }));
    dispatch(setisProjectChangeAC());
  };

  const resetProject = () => {
    dispatch(getAdminProjectTC(currentProject.id));
    dispatch(setCurrentProjectImageAC(null));
    dispatch(setisProjectChangeAC(false));
  };

  const openModalNotification = () => {
    showNotification(true);
  };

  const saveProject = () => {
    const executorsIds = currentProject.executors.map(
      (executor) => executor.id
    );

    currentProject.executors = executorsIds;
    const notificationsToUser = currentProject.notificationsToUser;

    if (notificationsToUser.length) {
      const rate = notificationsToUser[0].rate;
      const projectRate = currentProject.rate;

      if (rate !== projectRate.type) {
        notificationsToUser[0].rate = projectRate.type;
        notificationsToUser[0].notificationValue =
          +notificationsToUser[0].hours * +projectRate.value +
          ( +notificationsToUser[0].minutes / 60 ) * +projectRate.value;
      }
    }
    
    currentProject.notificationsToUser = JSON.stringify(notificationsToUser);

    currentProject['interviewerId'] = currentUser.id;

    dispatch(saveAdminProjectTC(currentProject));
  };

  const filePreviewWasUploaded = (files) => {
    const resultFile = files[0];
    dispatch(setCurrentProjectImageAC(resultFile));
    dispatch(setisProjectChangeAC());
  };

  const deleteProject = () => {
    dispatch(deleteProjectTC(id));
    afterZipUnzipPreparing();
  };

  const archiveProject = () => {
    dispatch(addProjectToArchiveTC(id));
    afterZipUnzipPreparing();
  };

  const unzipProject = () => {
    dispatch(removeProjectFromArchiveTC(id));
    afterZipUnzipPreparing();
  };

  const afterZipUnzipPreparing = () => {
    searchParams.delete('project_asset_id');
    searchParams.delete('admin_info_active');

    setSearchParams(searchParams);
    setActiveInfo('chat');

    const emptyProject = projectInstance.create();
    dispatch(setCurrentProjectAC(emptyProject));
    dispatch(setChatMessagesAC([]));
  };

  const renderArchiveBtn = () => {
    if (isArchive) {
      return (
        <>
          <Button size="lg" theme="secondary-danger" onClick={deleteProject}>
            Delete
          </Button>
          <Button size="lg" onClick={unzipProject}>
            Unzip project
          </Button>
        </>
      );
    }

    return (
      <Button size="lg" theme="secondary-danger" onClick={archiveProject}>
        Archive
      </Button>
    );
  };

  const editTimeCallback = (data, editTimeSpent) => {
    return setEditTimeSpentShow({
      state: true,
      stage: data.stage,
      timeSpent: data.data,
      callback: editTimeSpent,
    })
  }

  const stepperCallback = (data, switchActiveStage) => {
    if (data.step >= 7) {
      return getUploadArchivePopUp({
        state: true,
        stage: data.step,
        followingId: +followingId,
        callback: switchActiveStage,
      });
    }

    if (data.value && data.step !== 1) {
      return setStagePopUpShow({
        state: true,
        stage: data.step,
        date: Date().now,
        callback: switchActiveStage,
      });
    }

    return switchActiveStage();
  };

  const uploadArchive = async () => {
    if (!isArchiveIsString) {
      const file = await dispatch(uploadBinaryFileTC({dir, name: archive}));

      return downloadArchive(file);
    }

  
     window.open(
       archive,
       '',
       'toolbar=yes,scrollbars=yes,resizable=yes,top=0,bottom=0,left=2800,width=700,height=600'
     );
  }
  const deleteArchive = () => {
    if(followingId > 6) {
      dispatch(setProjectPropertieAC({ key: 'followingId', value: followingId - 1 }));
    }

    dispatch(
      updateAdminProjectPropertyTC({
        value: "",
        name: 'archive',
        alertText: "The archive was successfully deleted."
      })
    );
  }

  const downloadArchive = (blob) => {
    const link = document.createElement('a');
    link.setAttribute('download', blob.name);

    const href = URL.createObjectURL(blob);
    link.href = href;
    link.setAttribute('target', '_blank');
    link.click();
    URL.revokeObjectURL(href);
  }

  return (
    <>
      <div
        className={`d-flex ai-center project__info divider ${
          errors && errors.uploadImage && 'project__info-error'
        }`}
      >
        <UploadImage
          wasUploaded={filePreviewWasUploaded}
          maxSize={1572864} //1,5MB
          accept={'image/jpeg, image/png'}
          errorCallback={(error) => setErrors(error)}
        >
          {renderPreview()}
          <div className="button__image-add">
            <Icon src={PlusIcon} className="button__placeholder" />
          </div>
        </UploadImage>
        <textarea value={title} onChange={onTitleChange} />
      </div>
      {errors && errors.uploadImage && (
        <Text as="span" className="error">
          {errors.uploadImage}
        </Text>
      )}
      
      <div className="info__notification divider">
        <div className="mt-16 admin-notification">
          <Button
            className={''}
            theme="secondary"
            size="lg"
            iconLeft={PlusIcon}
            onClick={openModalNotification}
            isDisabled={isArchive || isProjectChanged}
          >
            Notification
          </Button>
        </div>
      </div>
      
      <div className="info__customer divider">
        <Text as="h6" className="info__title">
          Customer
        </Text>
        <div className="info__customer-container">
          <Text as="h5" className="customer__name">
            {`${user?.firstName} ${user?.lastName}`}
          </Text>
          <Text as="h6" className="customer__email">
            {user?.email}
          </Text>
        </div>
      </div>

      <div className={`info__stages ${!archive && 'divider'}`}>
        <Text as="h6" className="info__title">
          Project stages
        </Text>
        <StepperToggle
          followingId={+followingId}
          followingData={followingData}
          showCalendar={showCalendar}
          callback={stepperCallback}
          currentStepsHours={currentStepsHours}
          workingHoursSum={workingHoursSum}
          unpaidHours={unpaidHours}
          cost={price?.value || 0}
          editTimeCallback={editTimeCallback}
        />
      </div>

      {archive && (
       <DownloadInfoElement uploadArchive={uploadArchive} deleteArchive={deleteArchive}/>
       )}

      <div className="info__license divider">
        <Text as="h6" className="info__title">
          License
        </Text>
        <Licenses radioItems={RADIO_ITEMS} checkItem={changeLicense} />
      </div>

      <div className="info__team divider">
        <Text as="h6" className="info__title">
          The project team
        </Text>
        <AntdDropdown
          id="input-search"
          data={executorsList}
          placeholder="Select team member"
          mode="multiple"
          search={true}
          value={defaultExecutorsList}
          renderOption={renderExecutorPreview}
        />
        {isExecutors && (
          <div className="render-executors">
            {executors.map((executor) => (
              <AvatarLabel
                key={`executor-info-${executor.id}`}
                author={executor}
                showText={true}
                text={`#${executor.subRole}`}
              />
            ))}
          </div>
        )}
      </div>
      <div className="info__archive_actions">{renderArchiveBtn()}</div>

      { isProjectChanged && (
        <div className="info__actions">
          <Button 
            size="lg" 
            theme="secondary-gray" 
            onClick={resetProject}
          >
            Reset
          </Button>

          <Button 
            size="lg" 
            onClick={saveProject}
            isDisabled={isArchive}
          >
            Save changes
          </Button>
        </div>
      )}
    </>
  );
};

export default AdminProjectInfo;
