export const clearAllNode = (attr) => {
 const candidats = document.querySelectorAll(attr);
 if (candidats.length){
   candidats.forEach((node) => node.remove());
 }
};

export const searchValueToArray = (value) => {
    var re = /(,)\1+|[^\w\s ]/gi;

    console.log(value)

    var array = value
      .replace(re, ',')
      .split(',')
      .filter((value) => value && value !== ' ')
      .map((item) => item.trim());

    console.log(array)

    return array;
  }