import React from 'react';
import './short-info.scss';

import {Icon, Text} from '@ui-kit';
import Dollar from '@ui-kit/icons/dollar-green500.svg';
import Inprogress from '@ui-kit/icons/edit-3-blueLight500.svg';
import Wait from '@ui-kit/icons/clock-orange500.svg';
import Crown from '@ui-kit/icons/crown-pink500.svg';
import Download from '@ui-kit/icons/download-blueGray500.svg';
import Check from '@ui-kit/icons/check-green500.svg';
import Unpaid from '@ui-kit/icons/divide-circle-warning500.svg';
  
const ShortInfo = ({project}) => {
    const {followingId, isPaid, notificationsToUser} = project;

    const showNotificationLabel = ({ markType, id }) => {
        switch (
          markType //ready, wait, in_progress ,download, switch_license
        ) {
          case 'sketchReady':
          case 'illustrationReady':
            return (
              <div key={`notification-${id}`} className="icon__shape ready">
                <Icon src={Dollar} />
              </div>
            );
    
          case 'waitingResponse':
            return (
              <div key={`notification-${id}`} className="icon__shape wait">
                <Icon src={Wait} />
              </div>
            );
    
          case 'sourceReady':
            return (
              <div key={`notification-${id}`} className="icon__shape download">
                <Icon src={Download} />
              </div>
            );
    
          case 'switchingToExclusive':
            return (
              <div
                key={`notification-${id}`}
                className="icon__shape switch_license"
              >
                <Icon src={Crown} />
              </div>
            );
    
          default:
            //'workProgress'
            return (
              <div key={`notification-${id}`} className="icon__shape in_progress">
                <Icon src={Inprogress} />
              </div>
            );
        }
    };
    
    const getStage = () => {
    if (!followingId) return 'Brief';

    if (followingId >= 7) return 'Completed';
    if (followingId >= 5) return 'Illustrating';
    if (followingId >= 3) return 'Sketching';

    return 'Brief';    
    }

    const getPaid = () => {
    if (+isPaid) {
        return (
        <div className="short-info__paid paid">
            <Icon src={Check} /> 
            <Text as="h6">Paid</Text>
            </div>
        )
    } 

    return (
        <div className="short-info__paid unpaid">
            <Icon src={Unpaid} /> 
            <Text as="h6">Unpaid</Text>
        </div>
    )
    }

    return (
      <div className="short-info">
        <div className="short-info__wrapper">
          <div className="short-info__status">
              {notificationsToUser?.map(item => showNotificationLabel(item))}
          </div>
          <div className="short-info__stage">{getStage()}</div>
        </div>
          {getPaid()}
      </div>
    )
}

export default ShortInfo;